export const ModalPopups = {
	ADD_BANK_ACCOUNT: 'AddBankAccount',
	CREATE_APIKEY: 'CreateApiKey',
	CY_CHANGE_PASSWORD: 'ChangePassword',
	CY_NON_TRADING_ORDER_COMMON: 'NonTradingOrderCommonModal',
	CY_NON_TRADING_ORDER_IDENTITY: 'NonTradingOrderIdentityModal',
	CY_NON_TRADING_ORDER_REGISTRATION: 'NonTradingOrderRegistrationModal',
	CY_NON_TRADING_ORDER_LEVERAGE: 'NonTradingOrderLeverageModal',
	CY_TRANSFER_BETWEEN_ACCOUNTS: 'TransferBetweenAccountsModal',
	CY_WITHDRAWAL_BANK_TRANSFER: 'WithdrawalBankTransferModal',
	CY_WITHDRAWAL_CRYPTOWALLET: 'WithdrawalCryptoWallet',
	CY_CHANGE_MT_PASSWORD: 'ChangeMtPassword',
	CY_NON_TRADING_OPEN_ACCOUNT: 'NonTradingOpenAccount',
	CY_NON_TRADING_OPEN_DEMO_ACCOUNT: 'NonTradingOpenDemoAccount',
	CY_WITHDRAWAL_MALAYSIAN_BANK: 'WithdrawalMalaysianBank',
	CY_WITHDRAWAL_KOREAN_BANK: 'WithdrawalKoreanBank',
	CY_WITHDRAWAL_KENYAN_BANK: 'WithdrawalKenyanBank',
	CY_WITHDRAWAL_NIGERIAN_BANK: 'WithdrawalNigerianBank',
	CY_WITHDRAWAL_INDONESIAN_BANK: 'WithdrawalIndonesianBank',
	CY_WITHDRAWAL_INDIAN_BANK: 'WithdrawalIndianBank',
	REPORTS: 'Reports',
	J2TX_CHANGE_CURRENCY: 'J2txChangeCurrency',
	TRANSFER_WALLET_TO_MT5: 'J2txTransferModal',
	TRANSFER_MT5_TO_WALLET: 'J2txTransferModal',
	TRANSFER_WALLET_TO_STAKING: 'J2txTransferModal',
	TRANSFER_STAKING_TO_WALLET: 'J2txTransferModal',
	J2TX_WALLET_WITHDRAWAL: 'J2txWalletWithdrawal',
	J2TX_MT5_WITHDRAWAL: 'J2txMt5Withdrawal',
	DEPOSIT_FUNDS_ACH: 'DepositFundsAch',
	DEPOSIT_FUNDS_BROKER: 'DepositFundsBroker',
	DEPOSIT_FUNDS_WIRE: 'DepositFundsWire',
	WITHDRAW_FUNDS_ACH: 'WithdrawFundsAch',
	WITHDRAW_FUNDS_WIRE: 'WithdrawFundsWire',
	WITHDRAW_FUNDS_BROKER: 'WithdrawFundsBroker',
	J2T_LITE: 'LimeTradingPlatform',
	J2T_PLUS: 'J2tPlus',
	J2T_PRO: 'J2tPro',
	MT5: 'Mt5',
	ST_PRO: 'StPro',
	MESSAGE: 'Message',
	CHANGE_PROFILE_ADDRESS: 'ChangeProfileAddress',
	CHANGE_PROFILE_EMAIL: 'ChangeProfileEmail',
	CHANGE_PROFILE_PHONE: 'ChangeProfilePhone',
	CHANGE_DEPENDENTS: 'ChangeDependents',
	CHANGE_EMPLOYMENT: 'ChangeEmployment',
	CHANGE_MARKETDATA_SUBSCRIBER_STATUS_MODAL: 'ChangeMarketDataSubscriberStatus',
	CHANGE_MARKETDATA_SUBSCRIPTIONS_MODAL: 'ChangeMarketDataSubscriptions',
	ENABLE_THIRD_PARTY_MARKETDATA_MODAL: 'EnableThirdPartyMarketData',
	CHANGE_TWO_FACTOR_AUTHORIZATION: 'ChangeTwoFactor',
	COMMISSION_FREE_MODAL: 'CommissionFreeModal',
	CONFIRM_MODAL: 'ConfirmModal',
	CONFIRM_PROFILE_EMAIL: 'ConfirmProfileEmail',
	CREATE_SIGNATURE: 'CreateSignature',
	PLAID_SELECT_ACCOUNT: 'PlaidSelectAccount',
	WELCOME_PAGE: 'WelcomePage',
	DEMO_ACCOUNT_EXPIRATION: 'DemoAccountExpiration',
	AGENT_REGISTRATION_MODAL: 'AgentRegistrationForm',
	NEW_AGENT_WAITING_MODAL: 'NewAgentWaitingModal',
	COMMON_WITHDRAW_MODULE_MODAL: 'Withdraw',
};
