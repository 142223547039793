import React from 'react';
import { env } from '@config';
import { NotificationBannersGallery } from '@dev2t/react-ui-kit/src';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	bannersContainer: {
		borderRadius: '10px',
		boxShadow: '0 6px 16px 0 rgba(61, 65, 71, 0.12)',
		overflow: 'hidden',
		marginBottom: '30px',
	},
}));

export const BannersGallery = () => {
    const classes = useStyles();
    return (
        <div className={classes.bannersContainer}>
            <NotificationBannersGallery
                standardIntervalInMs={env.bannerAutoSlideStandardIntervalInMs}
                extendedIntervalInMs={env.bannerAutoSlideExtendedIntervalInMs}
            />
        </div>
    );
};
