import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTheme } from '@material-ui/core';
import {
	ContentCloud,
	ShowMore,
	LogoIcon, 
} from '@components';
import { R, useTranslate } from '@dev2t/react-ui-kit/src/components';
import moment from 'moment';
import { OperationType, AccountType } from '@enums';
import { useMoneyFormat, useAccount, useOnScreen } from '@hooks';
import { userAccounts } from '@redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import NoDataIcon from '@material-ui/icons/NotInterested';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
	headCell: {
		paddingTop: 0,
		paddingBottom: 0,
		border: 'none',
		fontSize: '12px',
		lineHeight: 1.5,
		verticalAlign: 'top',
		boxSizing: 'border-box',
		[theme.breakpoints.down('sm')]: {
			'&:first-of-type': {
				paddingLeft: 0,
			},
		},
	},
	rowCell: {
		paddingTop: '14px',
		paddingBottom: '14px',
		boxSizing: 'border-box',
		fontSize: '16px',
		color: theme.palette.text.primary,
		textWrap: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			'&:first-of-type': {
				paddingLeft: '4px',
			},
		},
	},
	open: {
		padding: '0 50px 32px',
		[theme.breakpoints.down('sm')]: {
			padding: '0 40px 20px',
		},
	},
	content: {
		overflowX: 'auto',
	},
}));

const MyTableRow = withStyles(theme => ({
	root: {
		height: '65px',
		borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
		'&:last-child': {
			borderBottom: 'none',
		},
	},
}))(TableRow);

const MyTableCell= withStyles(theme => ({
	root: {
		borderBottom: 'none',
	},
}))(TableCell);

export default function Transactions({ tradeCode }) {

	const theme = useTheme();
	const classes = useStyles();
	const dispatch = useDispatch();
	const translate = useTranslate();
	const [isOpen, setIsOpen] = useState(true);
	const moneyFormat = useMoneyFormat({ precision: 8, disableCurrencyCode: true });

	const loadMore = () => {
		payload.Page++;
		dispatch(userAccounts.actions.getMoneyTransactionsHistory.request(payload));
	}
		
	const activities = useSelector(userAccounts.selectors.cashActivities);
	const activitiesLoading = activities?.loading ?? true;

	const stakingAccounts = useAccount(AccountType.STAKING).map(x => x.TradeCode);

	const stakingHistory = activities?.items.map(x => {
		return {
			...x,
			// если переводим со staking счета, то добавляем минус
			Amount: stakingAccounts.includes(x.TradeCode) ? -x.Amount : x.Amount,
			Currency: x.Currency ?? x.CurrencyTo
		}
	})
	.filter(x => x.Currency) ?? [];
	
	const payload = {
		TradeCodes: [...stakingAccounts],
		// не было такой функциональности до этой даты
		StartDate: 20240101,
		EndDate: moment().format('YYYYMMDD'),
		Page: activities.pagination.Page ?? 1,
		PageSize: 10,
		Type: OperationType.ALL
	}

	useEffect(() => {
		dispatch(userAccounts.actions.getMoneyTransactionsHistory.request(payload));
		
		// автообновление списка транзакций
		if (isOpen) {
			const timer = setInterval(() => {
				dispatch(userAccounts.actions.getMoneyTransactionsHistory.request(payload));
			}, 5 * 60 * 1000);
			return () => clearInterval(timer);	
		}
	}, [isOpen]);

	useEffect(() => {
		return () => {
			dispatch(userAccounts.actions.clearTransastionsHistory());
		};
	}, []);

	const accountsLoading = useSelector(userAccounts.selectors.accountsInfoLoading);
	const loading = stakingHistory.length > 0
		? false
		: (accountsLoading || activitiesLoading) ?? true;

	const onToggleOpen = () => setIsOpen(open => !open);

	return (
		<ContentCloud
			isOpen={isOpen}
			submitting={loading}
			onToggleOpen={onToggleOpen}
			id={'StakingTransactions' + tradeCode}
			header={translate('staking_history', 'Staking History')}
		>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell classes={{ root: classes.headCell }}>
							<R
								id="staking_date"
								defaultValue="Staking Date"
							/>
						</TableCell>
						<TableCell classes={{ root: classes.headCell }}>
							<R
								id="common_cryptocurrency"
								defaultValue="Cryptocurrency"
							/>
						</TableCell>
						<TableCell classes={{ root: classes.headCell }}>
							<R
								id="shared_amount"
								defaultValue="Price"
							/>
						</TableCell>
						<TableCell classes={{ root: classes.headCell }}>
							<R
								id="shared_status"
								defaultValue="Status"
								/>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{stakingHistory.length > 0 ? 
						stakingHistory.map(x => 
							<MyTableRow  key={x.Date}>
								<MyTableCell>
									{moment.utc(x.Date).local().format('YYYY-MM-DD HH:mm:ss')}
								</MyTableCell>
								<MyTableCell>
									<LogoIcon currency={x.Currency} />
								</MyTableCell>
								<MyTableCell>
									{moneyFormat(x.Amount ?? 0, x.Currency)}
								</MyTableCell>
								<MyTableCell>
									{x.DisplayStatus}
								</MyTableCell>
							</MyTableRow>
						) : (
							<MyTableRow>
								<MyTableCell colSpan={6} classes={{ root: classes.noDataCell }}>
									<Box
										display="flex"
										justifyContent="center"
										alignItems="center"
										gridGap="10px"
									>
										<NoDataIcon />
										<R id="shared_no_data_available" defaultValue="No data" />
									</Box>
								</MyTableCell>
							</MyTableRow>
						)}
				</TableBody>
			</Table>
			<ShowMore
					submitting={activities.loading && activities.items && activities.items.length > 0}
					onClick={loadMore}
					showMoreButton={activities.pagination.Page * activities.pagination.PageSize <= activities.items.length}
					htmlColor={theme.palette.text.primary}
				/>
		</ContentCloud>
	);
}
