import React, { useEffect, useState }  from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { 
	Field,
	formValueSelector,
	reduxForm,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Button } from '@components';
import { R, FormInput } from '@dev2t/react-ui-kit/src/components';
import { constants } from '@config';
import { reset } from '@redux';
import { RequireConfirmationCodeItem, RequireConfirmationCodeReason } from '@shared';
import { translate } from '@global';

const styles = theme => ({
	root: {
		position: 'relative',
		'& input': {
			'&:-webkit-autofill': {
				transition: 'background-color 0.1s ease-in-out 0s',
				'-webkit-box-shadow': `0 0 0 1000px ${theme.palette.background.default} inset`,
				'-webkit-text-fill-color': theme.palette.text.primary,
			},
		},
	},
	divider: {
		marginTop: '30px',
	},
	warning: {
		padding: '12px 16px',
		textAlign: 'justify',
		fontSize: '12px',
		lineHeight: '20px',
	},
	button: {
		paddingBottom: '20px',
	},
});

const mapStateToProps = (state) => {
	const formSelector = formValueSelector(RESET_BY_EMAIL_FORM_ID);
	return {
		emailValue: formSelector(state, 'Email'),
	};
};

const mapDispatchToProps = dispatch => ({
	actions: {
		sendConfirmationCode: (email, byemail) => dispatch(reset.actions.sendConfirmationForResetByEmail.request({email, byemail})),
	},
});


const RESET_BY_EMAIL_FORM_ID = 'ResetByEmailForm';
class ResetByEmailForm extends React.Component {
	static validate(values) {

		const errors = {};
	
		if (!values) {
			return errors;
		}
	
		if (!values.Email) {
			errors.Email = 'required';
		} else if (!(constants.regExpEmail.test(values.Email))) {
			errors.Email = 'Invalid Format';
		}
	
		if (!values.ConfirmationCode) {
			errors.ConfirmationCode = 'required';
		}

		return errors;
	}
	

	render() {
		const {
			actions,
			classes,
			emailValue,
			handleSubmit,
			invalid,
			onOperatorSended,
			submitting,
			theme,
		} = this.props;

		return (
			<form
				onSubmit={handleSubmit(reset.actions.resetByEmail)}
				className={classes.root}
			>
				<Box marginBottom="10px">
					<Field
						component={FormInput}
						name="Email"
						label={translate('reset_form_your_email', 'Your Email')}
					/>
				</Box>
				<RequireConfirmationCodeItem
					customClasses={classes}
					name="ConfirmationCode"
					buttonTitle={<R id="reset_form_submit" defaultValue="Submit" />}
					reason={RequireConfirmationCodeReason.MoneyTransferApply}
					onSendCode={(email, reason, byemail) => actions.sendConfirmationCode(emailValue, byemail)}
					disabled={invalid || submitting}
				>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={invalid || submitting}
					>
						{submitting ?
							<CircularProgress
								style={{ color: theme.palette.common.white }}
								size={18}
							/>
							: <R id="reset_form_confirm" defaultValue="Confirm" /> // "Submit"
						}
					</Button>

				</RequireConfirmationCodeItem>
				<Button
					fullWidth
					type="button"
					variant="contained"
					onClick={onOperatorSended}
					color="secondary"
				>
					<R id="i_dont_remember" defaultValue="I don't remember" />
				</Button>
			</form>
		);
	}
}

export default compose(
	withTheme,
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: RESET_BY_EMAIL_FORM_ID,
		validate: ResetByEmailForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
	}),
)(ResetByEmailForm);
