import actions from './actions';
import auth from '../auth';
import { SessionStorageKey } from '@enums';

const initialState = {
	partnerCabinetRedirect: {
		submitting: false,
		loaded: false,
		data: {
			PartnerCabinetRedirectUrl: null,
			IsPartnerCabinetLoginAvailable: false,
			PartnerCabinetRedirectBlockExpiration: sessionStorage.getItem(SessionStorageKey.PartnerCabinetRedirectBlockExpiration),
		},
	},
	modals: {},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.agentRegistration.REQUEST:
		case actions.partnerCabinetRedirect.REQUEST:
			return {
				...state,
				partnerCabinetRedirect: {
					...state.partnerCabinetRedirect,
					submitting: true,
				},
			};
		case actions.agentRegistration.SUCCESS:
		case actions.partnerCabinetRedirect.SUCCESS:
			return {
				...state,
				partnerCabinetRedirect: {
					...state.partnerCabinetRedirect,
					data: {
						...state.partnerCabinetRedirect.data,
						PartnerCabinetRedirectUrl: payload.PartnerCabinetRedirectUrl,
						IsPartnerCabinetLoginAvailable: payload.IsPartnerCabinetLoginAvailable,
					},
					submitting: false,
					loaded: true,
				},
			};
		case actions.agentRegistration.FAILURE:
		case actions.partnerCabinetRedirect.FAILURE:
			return {
				...state,
				partnerCabinetRedirect: {
					...initialState.partnerCabinetRedirect,
					submitting: false,
					loaded: true,
				},
			};
		case actions.SET_PARTNER_CABINET_REDIRECT_BLOCK_EXPIRATION:
			return {
				...state,
				partnerCabinetRedirect: {
					...state.partnerCabinetRedirect,
					data: {
						...state.partnerCabinetRedirect.data,
						PartnerCabinetRedirectBlockExpiration: payload.expiration,
					}
				}
			 };
		case auth.actions.LOGOUT_SUCCESS:
			return { ...initialState };
		default:
			return state;
	}
};
