import { fork, takeEvery } from 'redux-saga/effects';
import { BaseGetSagaHandler } from '@redux';
import { getAlerts } from '@api';
import actions from './actions';

function* getAlertsRequestSaga({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: getAlerts,
		errorText: 'Get alerts',
		handler: actions.getAlerts,
	}, { payload });
}

function* watchGetAlertsRequest() {
	yield takeEvery(actions.getAlerts.REQUEST, getAlertsRequestSaga);
}

// Root saga
const rootSaga = [
	fork(watchGetAlertsRequest),
];

export default {
	rootSaga,
};
