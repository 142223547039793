import { IconButton, CircularProgress, Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DoneIcon from '@material-ui/icons/Done';
import { FormInput, useTranslate } from '@dev2t/react-ui-kit/src';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { captcha } from '@redux';
import classNames from 'classnames';
import { CaptchaSteps } from '@enums';

const styles = {
	image: {
        backgroundRepeat: 'round',
        backgroundSize: 'contain',
        width: '100%',
        height: '100%',
	},
	imageContainer: {
        height: '100%',
        width: '120px',
        borderLeft:  '1px solid lightgray',
        borderRight: '1px solid lightgray',
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
	},
	captcha: {
        display: 'flex',
        alignSelf: 'stretch',
        maxHeight: 'unset',
        height: 'unset',
        flexShrink: 0,
        alignItems: 'center'
	},
	inputType: {
		height: 'auto',
	},
	inputContainer: {
		padding: '0px 8px 0px 24px'
	}
};

const mapStateToProps = state => ({
	loading: captcha.selectors.loading(state),
    imageBase64: captcha.selectors.imageBase64(state),
    value: captcha.selectors.value(state),
    step: captcha.selectors.step(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
        resetCaptcha: () => dispatch(captcha.actions.reset.request()),
        setCaptchaInput: value => dispatch(captcha.actions.setCaptchaInput(value)),
	},
});

const CaptchaItem = ({
    classes,
    actions: { resetCaptcha, setCaptchaInput },
    imageBase64,
    step,
    value,
    ...props 
}) => {
	const translate = useTranslate();

    useEffect(() => { resetCaptcha() }, []);

    if (step === CaptchaSteps.VALIDATING_SUCCESS)
    {
        const done = <Box p={1.5}> <DoneIcon /> </Box>;

        return (
            <FormInput
                {...props}
                className={props.className}
                inputProps={{
                    className: classes.inputType,
                    disabled: true
                }}
                endAdornment={done}
                value={translate('shared_captcha_validation_successful', 'Code is correct')}
                showLabel={false}
                />)
    }

    const progress = <CircularProgress size={20} />;

    if (step === CaptchaSteps.VALIDATING)
    {
        const progressAdornment = <Box p={1.5}> {progress} </Box>;

        return (
            <FormInput
                {...props}
                className={props.className}
                inputProps={{
                    className: classes.inputType,
                    disabled: true
                }}
                endAdornment={progressAdornment}
                value={translate('shared_captcha_validation_in_process', 'Validating...')}
                showLabel={false}
                />)
    }

    const control = (
        <div className={classes.captcha}>
            <div className={classes.imageContainer}>
                {step === CaptchaSteps.LOADING_IMAGE && progress}
                {step === CaptchaSteps.LOADING_IMAGE_FAILURE && <div>Failed to load</div>}
                {step === CaptchaSteps.WAITING_FOR_INPUT 
                    && <div className={classes.image} style={{ backgroundImage: `url(data:image/png;base64,${imageBase64})`}} />}
            </div>

            <IconButton onClick={resetCaptcha} tabIndex={-1}>
                <AutorenewIcon />
            </IconButton>
        </div>);
    
    return (
        <FormInput
            {...props}
            className={classNames(props.className, classes.inputContainer)}
            inputProps={{
                className: classes.inputType,
            }}
            endAdornment={control}
            onInput={e => {
                setCaptchaInput(e.target.value);
                props.input?.onInput && props.input.onInput(e);
            }}
            value={value}
            placeholder={translate('shared_captcha_title', 'Enter code')}
            autoComplete="off"
            showLabel={false}
            />)
};


export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(CaptchaItem);
