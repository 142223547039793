import { fork, takeEvery, put } from 'redux-saga/effects';
import {
	makePartnerCabinetRedirectUrl,
	agentRegistration,
} from '@api';
import { BaseGetSagaHandler, userAccounts, app } from '@redux';
import actions from './actions';
import { SessionStorageKey } from '@enums';

function* partnerCabinetRedirectRequestSagaHandler({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: makePartnerCabinetRedirectUrl,
		errorText: 'Partner Account',
		handler: actions.partnerCabinetRedirect,
	}, { payload });
}

function* agentRegistrationRequestSagaHandler({ payload }) {
	yield BaseGetSagaHandler({
		apiMethod: agentRegistration,
		errorText: 'Partner Account',
		handler: actions.agentRegistration,
	}, { payload });
}

function* partnerCabinetRedirectSuccessSagaHandler({ payload }) {
	if (payload.response.PartnerCabinetRedirectUrl && payload.response.IsPartnerCabinetLoginAvailable) {
		window.location.href = payload.response.PartnerCabinetRedirectUrl;
	} else {
		yield put(app.actions.showNewAgentWaitingModal());
		const expiration = Date.now() + 1000 * 60 * 30; // 30 минут
		yield put(actions.setPartnerCabinetRedirectBlockExpiration({
			expiration: new Date(expiration).toISOString(),
		}));
	}
}

function* partnerCabinetRedirectBlockExpirationSagaHandler({ payload }) {
	sessionStorage.setItem(
		SessionStorageKey.PartnerCabinetRedirectBlockExpiration,
		payload.expiration
	);
}

function* watchAgentRegistrationSuccessSagaHandler({ payload }) {
	if (!payload.response.IsPartnerCabinetLoginAvailable) {
		yield put(app.actions.hideAgentRegistrationFormModal());
		yield put(app.actions.showNewAgentWaitingModal());
		
		const expiration = Date.now() + 1000 * 60 * 30; // 30 минут
		yield put(actions.setPartnerCabinetRedirectBlockExpiration({
			expiration: new Date(expiration).toISOString(),
		}));
		return;
	}
	const w = window.open(payload.response.PartnerCabinetRedirectUrl, '_blank');
	if (w) {
		yield put(app.actions.hideAgentRegistrationFormModal());
		yield put(userAccounts.actions.getAccountsRequest());
		w.focus();
	} else {
		// Если всплывающие окна заблокированы
		window.location.href = payload.response.PartnerCabinetRedirectUrl;
	}
}
function* watchAgentRegistrationFailureSagaHandler({ payload }) {
	yield put(app.actions.hideAgentRegistrationFormModal());
}

function* watchPartnerCabinetRedirectRequest() {
	yield takeEvery(actions.partnerCabinetRedirect.REQUEST, partnerCabinetRedirectRequestSagaHandler);
}
function* watchPartnerCabinetRedirectSuccess() {
	yield takeEvery(actions.partnerCabinetRedirect.SUCCESS, partnerCabinetRedirectSuccessSagaHandler);
}
function* watchAgentRegistrationRequest() {
	yield takeEvery(actions.agentRegistration.REQUEST, agentRegistrationRequestSagaHandler);
}
function* watchAgentRegistrationSuccess() {
	yield takeEvery(actions.agentRegistration.SUCCESS, watchAgentRegistrationSuccessSagaHandler);
}
function* watchAgentRegistrationFailure() {
	yield takeEvery(actions.agentRegistration.FAILURE, watchAgentRegistrationFailureSagaHandler);
}
function* watchPartnerCabinetRedirectBlockExpiration() {
	yield takeEvery(actions.SET_PARTNER_CABINET_REDIRECT_BLOCK_EXPIRATION, partnerCabinetRedirectBlockExpirationSagaHandler);
}

// Root saga
const rootSaga = [
	fork(watchPartnerCabinetRedirectRequest),
	fork(watchPartnerCabinetRedirectSuccess),
	fork(watchAgentRegistrationRequest),
	fork(watchAgentRegistrationSuccess),
	fork(watchAgentRegistrationFailure),
	fork(watchPartnerCabinetRedirectBlockExpiration),
];

export default {
	rootSaga,
};
