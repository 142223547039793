import React, { useState } from 'react';
import { ModalPopups } from '@enums';
import { R, Modal, ErrorMessage, SuccessMessage } from '@dev2t/react-ui-kit/src/components';
import { withdrawFunds } from '@redux';
import { J2txCurrencyChangeForm } from './components';

const J2txCurrencyChangeModal = props => {
	const { onClose } = props;

	const [errorMessage, setErrorMessage] = useState('');

	const [step, setStep] = useState('amountform');
	const onSuccess = () => setStep('succeeded');
	const onFail = message => {
		setErrorMessage(message);
		setStep('failed');
	};

	return (
		<Modal
			title={<R id="exchnage_currency" defaultValue="Exchange currency" />}
			onClose={onClose}
			maxWidth="sm"
			className={`QA-MODAL-${ModalPopups.J2TX_CHANGE_CURRENCY}`}
		>
			{step === 'amountform'
				&& (
					<J2txCurrencyChangeForm
						onSubmit={withdrawFunds.actions.exchangeCurrencySubmitForm}
						onSuccess={onSuccess}
						onFail={onFail}
					/>
				)}
			{step === 'succeeded'
				&& (
					<SuccessMessage
						onClick={onClose}
						text={<R id="common_banking_transaction_completed" defaultValue="Banking transaction completed" />}
					/>
				)}
			{step === 'failed'
				&& 				(
					<ErrorMessage
						onClick={onClose}
						text={errorMessage
							|| <R id="common_could_not_complete_transaction" defaultValue="Could not complete transaction" />}
					/>
				)}
		</Modal>
	);
};

export default J2txCurrencyChangeModal;
