import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import {
	FormSelect,
	Divider,
	Button,
} from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { translate } from '@global';
import { constants } from '@config';
import { userAccounts, app } from '@redux';
import { template } from '@templates';
import { imgBitcoin, imgEthereum, imgRipple, imgTether } from '@content/paysystemLogos';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		paddingLeft: '50px',
	},
	divider: {
		marginTop: '30px',
		marginBottom: '20px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	methodsContainer: {
		marginBottom: '24px',
	},
	methodItem: {
		textAlign: 'center',
		height: '100%',
		padding: '20px',
		border: '2px solid #deeaef',
		borderRadius: '8px',
		'&:hover': {
			boxShadow: '0 5px 10px 0 rgba(61, 65, 71, 0.16)',
			borderWidth: '2px',
			transform: 'translate(0, 1.5px) scale(1.01)',
		},
	},
	methodItemSelected: {
		borderColor: theme.palette.text.secondary,
	},
	methodImg: {
		maxWidth: '120px',
		height: '80px',
		[theme.breakpoints.up('sm')]: {
			height: '120px',
		},
	},

});

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const currentAccountDisplayFullName = userAccounts.selectors.currentAccountDisplayFullName(state);
	const language = app.selectors.language(state);
	const platform = userAccounts.selectors.accounts(state)
		.find(x => x.TradeCode === currentTradeAccount.tradeCode && x.ClearingFirm === currentTradeAccount.clearerFirm)
		.Platform;
	const initialValues = {
		NonOrderData: {
			ConfirmationCode: null,
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
		},
		EMoneyOut: {
			SelectedAccount: `${currentTradeAccount.tradeCode};${platform}`,
			IsAllOut: false,
			IsCrypto: true,
			Urgently: false,
			Summ: 0,
			Currency: 'USD',
			AccountCurrency: '',
			Comment: '',
			Reason: constants.MoneyOutReason.NeedMoney,
			ReasonComment: '',
			SelectTarget: '',
			TargetAccount: '',
		},
	};
	const EMoneyOut = formValueSelector(ownProps.form)(state, 'EMoneyOut') || initialValues.EMoneyOut;
	return {
		initialValues,
		EMoneyOut,
		currentTradeAccount,
		currentAccountDisplayFullName,
		language,
	};
};
const getMethods = () => [
	{
		SelectTarget: 'BITCOIN',
		Title: translate('orders_selectmethod_bitcoin_title', 'Bitcoin Wallet'),
		AccountCurrency: 'MBC',
		Img: imgBitcoin,
	},
	{
		SelectTarget: 'ETHER',
		Title: translate('orders_selectmethod_ethereum_title', 'Ethereum'),
		AccountCurrency: 'ETH',
		Img: imgEthereum,
	},
	{
		SelectTarget: 'TETHER',
		Title: translate('orders_selectmethod_tether_title', 'Tether'),
		AccountCurrency: 'USDT',
		Img: imgTether,
	},
	{
		SelectTarget: 'RIPPLE',
		Title: translate('orders_selectmethod_ripple_wallet_title', 'Ripple'),
		AccountCurrency: 'XRP',
		Img: imgRipple,
	},
];

class CYWithdrawalCryptoWalletSelectMethod extends React.Component {
	static validate(formvalues, props) {
		const values = formvalues;
		const errors = {};

		if (!values) {
			return errors;
		}

		if (values.EMoneyOut) {
			errors.EMoneyOut = {};
	
			if (!values.EMoneyOut.Currency) {
				errors.EMoneyOut.Currency = 'required';
			}
			if (!values.EMoneyOut.SelectTarget) {
				errors.EMoneyOut.SelectTarget = 'required';
			}
			if (!values.EMoneyOut.AccountCurrency) {
				errors.EMoneyOut.AccountCurrency = 'required';
			}
		}

		return errors;
	}
	selectMethod = (m) => {
		const { EMoneyOut, change } = this.props;
		change('EMoneyOut', {
			...EMoneyOut,
			AccountCurrency: m.AccountCurrency,
			SelectTarget: m.SelectTarget,
		});
	};

	render() {
		const {
			classes,
			fromUserAccount,
			handleSubmit,
			submitSucceeded,
			amount,
			theme,
			currentTradeAccount,
			currentAccountDisplayFullName,
			values,
			initialValues,
			EMoneyOut,
			...props
		} = this.props;

		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<div className={classes.accountContainer}>
					<div className={classes.row}>
						<div className={classes.logo}>
							<template.firmLogo />
						</div>
						<Field component={FormSelect} name="NonOrderData.TradeCode" label={translate('common_account', 'Account')} disabled>
							<MenuItem value={currentTradeAccount.tradeCode}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{currentAccountDisplayFullName}</div>
								</div>
							</MenuItem>
						</Field>
					</div>
				</div>
				<div className={classes.divider}>
					<Divider />
				</div>
				<div className={classes.methodsContainer}>
					<Grid container spacing={3}>
						{getMethods().map(m => (
							<Grid item xs={6} key={m.SelectTarget} >
								<div
									className={classNames({
										[classes.methodItem]: true,
										[classes.methodItemSelected]: EMoneyOut.SelectTarget === m.SelectTarget,
									})}
									onClick={() => this.selectMethod(m)}
								>
									<img src={m.Img} alt={m.AccountCurrency} className={classes.methodImg} />
									<div className={classes.methodTitle}>{m.Title}</div>
								</div>
							</Grid>
						))}
					</Grid>
				</div>
				<Button
					fullWidth
					type="submit"
					variant="contained"
					color="primary"
					disabled={props.invalid || props.submitting || !EMoneyOut.SelectTarget}
				>
					{props.submitting ?
						<CircularProgress
							style={{ color: theme.palette.common.white }}
							size={18}
						/>
						: <R id="common_btn_continue" defaultValue="Continue" />
					}
				</Button>
			</form>
		);
	}
}

CYWithdrawalCryptoWalletSelectMethod.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountDisplayFullName: PropTypes.string.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
	reduxForm({
		form: 'CYWithdrawalCryptoWalletSelectMethod',
		validate: CYWithdrawalCryptoWalletSelectMethod.validate,
	}),
)(CYWithdrawalCryptoWalletSelectMethod);
