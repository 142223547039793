import React from 'react';
import { userAccounts } from '@redux';
import { useSelector } from 'react-redux';
import { List  } from '@material-ui/core';
import { ContentCloud } from '@components';
import { useTranslate } from '@dev2t/react-ui-kit/src/components';
import { useAccount, useBalance } from '@hooks';
import { constants } from '@config';
import { AccountType } from '@enums';
import { WalletListItem, J2txWalletTransactionsHistory } from './components';

const VisibleCurrencies = [
	...constants.J2txWalletAllowedCurrencies,
	...constants.J2txWalletAllowedCryptoCurrencies,
];

export default function Wallet() {
	const translate = useTranslate();

	const walletAccounts = useAccount(AccountType.WALLET);
	const balance = useBalance(walletAccounts.map(x => x.TradeCode))
	const loading = useSelector(userAccounts.selectors.accountsInfoLoading);
	// ищем wallet счета
	const walletTradeCodes = walletAccounts
		.map(x => x.TradeCode);

	return (
		<>
			{walletTradeCodes?.map(tradeCode => (
				<ContentCloud 
						key={tradeCode}
						submitting={loading}
						id={'ExpansionWallet' + tradeCode}
						header={translate('shared_wallet', 'Wallet')}
					>
					<List component="nav" aria-label="mailbox folders">
						{VisibleCurrencies.map(currency => (
							<WalletListItem
								tradeCode={tradeCode}
								moneyInfoItem={balance?.get(tradeCode).find(x => x.CurrencyCode === currency) ?? { Balance: 0, CurrencyCode: currency }}
								key={currency}
							/>
						))}
					</List>
				</ContentCloud>
			))}
			<J2txWalletTransactionsHistory />
		</>
	);
}
