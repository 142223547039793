import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ProgressContainer } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { constants, env, allowedWithdrawalByCountry } from '@config';
import { ModalPopups } from '@enums';
import { translate } from '@global';
import { app, linkedAccounts as linkedAccountsRedux, userAccounts } from '@redux';
import {
	BankAccountItem,
	BankAccountItemCryptoWalletCY,
	BankAccountItemIndonesianBankCY,
	BankAccountItemIndianBankCY,
	BankAccountItemMalayBankCY,
	BankAccountItemKoreanBankCY,
	BankAccountItemKenyanBankCY,
	BankAccountItemNigerianBankCY,
	FundWidgetButton,
} from './components';
import WithdrawModuleButton from './components/WithdrawModuleButton/WithdrawModuleButton';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
	listItemContainer: {
		padding: '0 16px',
		margin: '0px -16px',
	},
	item: {
		borderBottom: '1px solid #deeaef',
		padding: '10px 0',
	},
	disabledFundsLabel: {
		...theme.customstyles.title,
		color: theme.palette.text.secondary,
		textAlign: 'center',
		margin: '10px 0'
	},
	buttonsRow: {
		display: 'flex',
		gap: '32px',
		justifyContent: 'center'
	}
}));

const useAvailableWithdrawalTypes = () => {
	const userInfo = useSelector(userAccounts.selectors.userInfo);
	const isWithdrawAllowedFor = useCallback((bankType) => {
		return allowedWithdrawalByCountry?.[env.firm]?.[bankType]?.some(x => x === userInfo?.Country);
	}, [allowedWithdrawalByCountry])
	return isWithdrawAllowedFor;
}

export const CYCashManagement = () => {
	const theme = useTheme();
	const classes = useStyles();

	const userInfo = useSelector(userAccounts.selectors.userInfo);
	const hasBlockers = userInfo.Debts && userInfo.Debts.some(it => it.IsBlockingMoneyOut);

	const currentTradeAccount = useSelector(userAccounts.selectors.currentTradeAccount);
	const accounts = useSelector(userAccounts.selectors.accounts);
	const accountStatus = useSelector(userAccounts.selectors.currentAccountStatus);
	const linkedAccounts = useSelector(userAccounts.selectors.linkedAccounts);
	const linkedAccountsSubmitting = useSelector(userAccounts.selectors.linkedAccountsSubmitting);
	const hasMaxTemplatesCount = useSelector(userAccounts.selectors.hasMaxTemplatesCount);
	const currentAccount = accounts.find(x => currentTradeAccount.tradeCode === x.TradeCode);
	const isLimeAgentAccount = currentAccount && currentAccount.IsLimeAgent;
	const dispatch = useDispatch();
	const showDepositFundsModal = bankAccount => {
		dispatch(app.actions.showBaseModal(ModalPopups.DEPOSIT_FUNDS_WIRE, { bankAccount }));
	};

	const showWithdrawFundsModal = bankAccount => {
		const userAccount = {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		};

		dispatch(app.actions.showBaseModal(ModalPopups.CY_WITHDRAWAL_BANK_TRANSFER, { userAccount, bankAccount }));
	};

	const showTransferBetweenAccountsModal = fakeAccount => {
		const userAccount = {
			tradeCode: currentTradeAccount.tradeCode,
			clearer: currentTradeAccount.clearerFirm,
		};

		dispatch(app.actions.showBaseModal(ModalPopups.CY_TRANSFER_BETWEEN_ACCOUNTS, { userAccount, bankAccount: fakeAccount }));
	};

	const unlinkAccount = bankAccount => {
		const text = `Please confirm if you want to remove template with ${bankAccount.BankName}`;
		dispatch(app.actions.showConfirmModal({
			text,
			action: onEndRequest => dispatch(linkedAccountsRedux.actions.wireUnlinkBankAccount.request({
				...currentTradeAccount,
				bankCode: bankAccount.Code,
				onEndRequest,
			})),
		}));
	};
	const isWithdrawAllowedFor = useAvailableWithdrawalTypes();

	const isWidgetEnabledForUser =
		env.WEBCAB_7011_SHOW_WITHDRAW_WIDGET &&
		(env.WEBCAB_7011_SHOW_WITHDRAW_WIDGET.includes('*') ||
			(userInfo.Guid &&
				env.WEBCAB_7011_SHOW_WITHDRAW_WIDGET.some(
					(item) => item.toLowerCase() === userInfo.Guid.toLowerCase()
				)));

	const enableWithdrawWidget = isWidgetEnabledForUser && [constants.LIME_LME, constants.LIME_INT, constants.LIME_NSR].some(x => x === env.firm);

	const fundAvailable = [constants.LIME_INT, constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm) && !isLimeAgentAccount;
	const showNoFundSign = !fundAvailable && isLimeAgentAccount;

	return (
		<div>
			<div className={classNames({ [classes.buttonsRow]: !showNoFundSign })}>
				{fundAvailable && <FundWidgetButton />}
				{showNoFundSign && (
						<div className={classes.disabledFundsLabel}>
							<R id="cannot_fund_this_type_of_account" defaultValue="You cannot fund this type of account" />
						</div>
					)}
				{enableWithdrawWidget && <WithdrawModuleButton />}
			</div>

			{!enableWithdrawWidget && !hasBlockers
				&& (
					<ProgressContainer submitting={linkedAccountsSubmitting} disableCheckChildren display="left">
						{accountStatus && !accountStatus.IsDisabled && !accountStatus.IsClosed
						&& (
							<div>
								{/* templates for withdrawal */}
								{linkedAccounts.map((bankAccount, index) => (
									<div className={classes.listItemContainer} key={index}>
										<div className={classes.item}>
											<BankAccountItem
												isLimeInternationalStyle
												bankAccount={bankAccount}
												hasDepositButton={() => (env.firm === constants.LIME_NSR)}
												hasWithdrawalButton={() => accountStatus.CanWithdrawal}
												onDepositFundsModal={showDepositFundsModal}
												onWithdrawFundsModal={showWithdrawFundsModal}
												onUnlinkAccount={unlinkAccount}
											/>
										</div>
									</div>
								))}
								{!hasMaxTemplatesCount && accountStatus.CanWireLink
								&& ([constants.LIME_LME, constants.FIRM_J2TX].every(x => x !== env.firm))
								&& (
									<div className={classes.listItemContainer} key="defaultWireLink">
										<div className={classes.item}>
											<BankAccountItem
												isLimeInternationalStyle
												bankAccount={{ Type: 'BankTransfer', displayNumber: translate('common_account_not_linked', 'Bank\'s Account is not linked') }}
												hasDepositButton={() => (env.firm === constants.LIME_NSR)}
												hasWithdrawalButton={() => accountStatus.CanWithdrawal}
												onDepositFundsModal={showDepositFundsModal}
												onWithdrawFundsModal={showWithdrawFundsModal}
												onUnlinkAccount={unlinkAccount}
											/>
										</div>
									</div>
								)}
								{accountStatus.CanWithdrawal
								&& [constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)
								&& (
									<div className={classes.listItemContainer}>
										<div className={classes.item}>
											<BankAccountItemCryptoWalletCY />
										</div>
									</div>
								)}
								{accountStatus.CanWithdrawal
								&& [constants.LIME_LME].some(x => x === env.firm)
								&& (isWithdrawAllowedFor("BankAccountItemMalayBankCY"))
								&& (
									<div className={classes.listItemContainer}>
										<div className={classes.item}>
											<BankAccountItemMalayBankCY />
										</div>
									</div>
								)}
								{accountStatus.CanWithdrawal
								&& [constants.LIME_LME].some(x => x === env.firm)
								&& (isWithdrawAllowedFor("BankAccountItemKoreanBankCY"))
								&& (
									<div className={classes.listItemContainer}>
										<div className={classes.item}>
											<BankAccountItemKoreanBankCY />
										</div>
									</div>
								)}
								{accountStatus.CanWithdrawal
								&& [constants.LIME_LME].some(x => x === env.firm)
								&& (isWithdrawAllowedFor("BankAccountItemKenyanBankCY"))
								&& (
									<div className={classes.listItemContainer}>
										<div className={classes.item}>
											<BankAccountItemKenyanBankCY />
										</div>
									</div>
								)}
								{accountStatus.CanWithdrawal
								&& [constants.LIME_LME].some(x => x === env.firm)
								&& (isWithdrawAllowedFor("BankAccountItemNigerianBankCY"))
								&& (
									<div className={classes.listItemContainer}>
										<div className={classes.item}>
											<BankAccountItemNigerianBankCY />
										</div>
									</div>
								)}
								{accountStatus.CanWithdrawal
								&& [constants.LIME_LME].some(x => x === env.firm)
								&& (
									<div className={classes.listItemContainer}>
										<div className={classes.item}>
											<BankAccountItemIndonesianBankCY />
										</div>
									</div>
								)}
								{accountStatus.CanWithdrawal
								&& [constants.LIME_LME].some(x => x === env.firm)
								&& (isWithdrawAllowedFor("BankAccountItemIndianBankCY"))
								&& (
									<div className={classes.listItemContainer}>
										<div className={classes.item}>
											<BankAccountItemIndianBankCY />
										</div>
									</div>
								)}
							</div>
						)}
					</ProgressContainer>
				)}

			{!enableWithdrawWidget
			    && accounts 
				&& accountStatus.CanTransferFrom
				&& accounts.filter(account => !account.IsDemo).length > 1
				&& accounts.filter(it => it.TradeCode !== currentTradeAccount.tradeCode && it.CanTransferTo).length > 0

				&& (
					<div className={classes.listItemContainer} key="TransferBetweenAccounts">
						<div className={classes.item}>
							<BankAccountItem
								isLimeInternationalStyle
								bankAccount={{
									Type: 'TransferBetweenAccounts',
									displayNumber: translate(
										'transfer_between_accounts_first_letter_uppercase',
										'Transfer Between Accounts',
									),
									LogoFileName: 'accounts-transfer.png',
									LogoColors: {
										primary: 'white',
										border: `1px dashed ${theme.palette.text.secondary}`,
									},
								}}
								hasDepositButton={() => false}
								hasWithdrawalButton={() => accountStatus.CanTransferFrom}
								withdrawalButtonText={<R id="transfer_between_accounts_button" defaultValue="Transfer" />}
								onWithdrawFundsModal={showTransferBetweenAccountsModal}
							/>
						</div>
					</div>
				)}

		</div>
	);
};
