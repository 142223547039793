import React from 'react';
import { env } from '@config';
import { Paper } from '@material-ui/core';
import { NotificationBannersGallery } from '@dev2t/react-ui-kit/src';

export const BannersGalleryJ2TX = () => (
    <Paper style={{ overflow: 'hidden', marginBottom: '30px' }}>
        <NotificationBannersGallery
            standardIntervalInMs={env.bannerAutoSlideStandardIntervalInMs}
            extendedIntervalInMs={env.bannerAutoSlideExtendedIntervalInMs}
        />
    </Paper>
);
