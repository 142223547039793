import React from 'react';
import { translate } from '@global';
import TotpCodeField from '../TotpCodeField/TotpCodeField';
import { R } from '@dev2t/react-ui-kit/src/components';

export default ({
	confirmationCodeFieldName,
	...props
}) => {
	return (
		<>
			<p>
				<R id="shared_totp_input_title" defaultValue="Enter the 6-digit code from the code-generating app" />
			</p>
			<TotpCodeField
				{...props}
				name={confirmationCodeFieldName}
				title={translate("shared_verification_code", "Verification Code")}/>
		</>
	)
}