import actions from './actions';
import reducer from './reducer';
import saga from './saga';
import selectors from './selectors';

export default {
	actions,
	reducer,
	saga,
	selectors,
};
