import React from 'react';
import { WithdrawWidget, useTranslate, Modal } from '@dev2t/react-ui-kit/src';
import { template } from '@templates';
import { userAccounts } from '@redux';
import { useSelector } from 'react-redux';
import { useAsyncPostRequest } from './useAsyncBackendRequest';
import { ThemeProvider } from '@material-ui/core';
import { originalTheme } from '@config/theme'
import { app } from '@redux';

const CommonWithdrawModuleModal = ({ onClose }) => {
	const translate = useTranslate();
	const post = useAsyncPostRequest();

	const currentTradeAccount = useSelector(userAccounts.selectors.currentTradeAccount);

	const postWithProxy = async (url, body) => {

		const req = {
			path: url,
			body: JSON.stringify(body),
		};

		const resp = await post(
			req, 
			'Error while loading data for withraw');

		return {
			data: resp.ServiceResponseBody && JSON.parse(resp.ServiceResponseBody) || null,
			code: resp.ServiceResponseHttpCode,
		}
	};
	
	const language = useSelector(app.selectors.language);
	
	return (
		<Modal
			title={translate("shared_withdraw", "Withdraw")}
			onClose={onClose}
		>
			{/* используем оригинальную тему, т.к. локальные изменения темы портят разметку. Убрать в рамках WEBCAB-8445 */}
			<ThemeProvider theme={originalTheme}>
				<WithdrawWidget
					performPostRequest={postWithProxy}
					selectedAccountTradeCode={currentTradeAccount.tradeCode}
					firmLogo={<template.firmLogo />}
					exitWidget={onClose}
					showSelectedAccountOnTopOfWithdrawForm
					selectedLanguage={language}
				/>
			</ThemeProvider>
		</Modal>
	);
}

export default CommonWithdrawModuleModal;
