import React from 'react';
import { useSelector } from 'react-redux';
import { userAccounts, alerts } from '@redux';
import { R } from '@dev2t/react-ui-kit/src/components';
import GenericTextAlert from './GenericTextAlert';

const NoAccountsAlert = () => {
	const loading = useSelector(userAccounts.selectors.loading);
	const accounts = useSelector(userAccounts.selectors.accounts);

	const alertItems = useSelector(alerts.selectors.alerts)?.items || [];
	const alertsLoading = useSelector(alerts.selectors.loading);
	if (loading || accounts?.length > 0 || alertsLoading || alertItems.length) return null;

	return (
		<GenericTextAlert
			text={<R id="shared_no_accounts" defaultValue="You have no accounts" />}
		/>
	);
};

export default NoAccountsAlert;
