import React from 'react';
import compose from 'recompose/compose';
import { userAccounts } from '@redux';
import { useDispatch, useSelector } from 'react-redux';
import {
	getFormValues,
	reduxForm,
	Field,
} from 'redux-form';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import {
	WithdrawAmount,
	FormCheckbox,
	FormSelect,
} from '@components';
import { Button, R, useTranslate } from '@dev2t/react-ui-kit/src/components';
import { Big } from '@dev2t/react-ui-kit/src';
import { useAccount, useBalance, useMinTransferValues } from '@hooks';
import { RequireConfirmationCodeItem, RequireConfirmationCodeReason } from '@shared';
import getDestinationAccounts from './getDestinationAccounts';
import getAvailableCurrencies from './getAvailableCurrencies';
import { InputAdornment } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	paramRow: {
		display: 'flex',
		margin: theme.spacing(3, 0),
		columnGap: theme.spacing(4),
		'& a': {
			color: theme.palette.primary.main,
			textDecoration: 'none',
		},
	},
	paramAmount: {
		flex: '1 1 auto',
	},
	paramCurrency: {
		flex: '0 0 100px',
	},
	fixWithdrawAmount: {
		paddingLeft: '0px',
		marginTop: '-10px',
		marginLeft: '-24px',
	},
	maxButton: {
		color: theme.palette.primary.main
	}
}));

const J2txTransferForm = ({ invalid, submitting, handleSubmit, form, change, untouch }) => {

	const theme = useTheme();
	const classes = useStyles();
	const dispatch = useDispatch();
	const translate = useTranslate();

	const { TradeCodeTo, TradeCodeFrom, SummCurrency } = useSelector(getFormValues(form)) || {};

	if (!TradeCodeFrom) throw new Error('TradeCodeFrom not provided');
	const sourceAccount = useAccount(TradeCodeFrom);

	// вычисляем счет куда переводим
	const destinationAccounts = getDestinationAccounts(sourceAccount);

	const destinationAccount = TradeCodeTo
		? useAccount(TradeCodeTo)
		: destinationAccounts[0];

	// вычисляем вылюту
	const currencies = getAvailableCurrencies(sourceAccount, destinationAccount);

	const loadingAccounts = useSelector(userAccounts.selectors.accountsInfoLoading);
	const balances = useBalance(TradeCodeFrom);

	const balanceForCurrency = balances
		.find(x => x.CurrencyCode === SummCurrency)
		.Balance;

	const refreshBalance = () => dispatch(userAccounts.actions.getAllAccountsInfo.request());
	const commissionText = '';

	const onSummCurrencyChange = (event, value) => {
		change('SummCurrency', value);
		change('Summ', '');
		untouch('Summ');
	}

	const onClickMax = () => {
		change('Summ', balanceForCurrency);
	}

	return (
		<form
			onSubmit={handleSubmit}
			className={classes.root}
		>
			<div className={classes.paramRow}>
				<div className={classes.paramAmount}>
					<WithdrawAmount
						name="Summ"
						validationMessageMode="afterAdditional"
						loading={loadingAccounts}
						availableAmount={balanceForCurrency}
						additionalText={commissionText}
						allowIconButtons={false}
						prefix=""
						currency={SummCurrency}
						onClick={refreshBalance}
						classes={{ inputItem: classes.fixWithdrawAmount }}
						endAdornment={
							<InputAdornment position="end">
							  <Button onClick={onClickMax} size="small" classes={{ root: classes.maxButton }}>
								Max
							  </Button>
							</InputAdornment>
						}
						placeholder={translate('shared_amount', 'Amount')}
					/>
				</div>
				<div className={classes.paramCurrency}>
					<Field component={FormSelect} onChange={onSummCurrencyChange} name="SummCurrency" label={translate('currency', 'Currency')}>
						{
							currencies.map(currency => (
								<MenuItem value={currency} key={currency}>
									<div className={classes.inputContainer}>
										<div className={classes.account}>{currency}</div>
									</div>
								</MenuItem>
							))
						}
					</Field>
				</div>
			</div>
			<div className={classes.paramRow}>
				<Field component={FormSelect} name="TradeCodeFrom" label={translate('shared_account_from', 'Account from')} disabled>
					{
						[sourceAccount].filter(x => !x.IsDemo).map((brokerAccount, index) => (
							<MenuItem value={brokerAccount.TradeCode} key={index}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{brokerAccount.DisplayFullName}</div>
								</div>
							</MenuItem>
						))
					}
				</Field>
			</div>
			<div className={classes.paramRow}>
				<Field component={FormSelect} name="TradeCodeTo" label={translate('shared_account_to', 'Account to')} disabled={destinationAccounts.length < 2}>
					{
						destinationAccounts.filter(acc => !acc.IsDemo).map((brokerAccount, index) => (
							<MenuItem value={brokerAccount.TradeCode} key={index}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{brokerAccount.DisplayFullName}</div>
								</div>
							</MenuItem>
						))
					}
				</Field>
			</div>
			{destinationAccount.IsStaking && 
			(
				<div className={classes.paramRow}>
					<Field
						component={FormCheckbox}
						name="Confirmation"
						label={(
							<>
								<R id="staking_confirmation" defaultValue='I confirm that I have read and agree to the <a href="https://j2t.global/files/limeltd/regulatorydocs/Terms%20and%20Conditions%20Lime%20Ltd.pdf" target="_blank">Terms of Use</a>' enableHtml />
							</>
						)}
					/>
				</div>
			)}
			<RequireConfirmationCodeItem
				customClasses={classes}
				name="ConfirmationCode"
				buttonTitle={<R id="shared_withdraw" defaultValue="Withdraw" />}
				reason={RequireConfirmationCodeReason.MoneyTransferApply}
				disabled={invalid || submitting}
				disableConfirmation
			>
				<Button
					fullWidth
					type="submit"
					variant="contained"
					color="primary"
					disabled={invalid || submitting}
				>
					{submitting
						? (
							<CircularProgress
								style={{ color: theme.palette.common.white }}
								size={18}
							/>
						)
                        : <R id="common_confirm_order" defaultValue="Confirm" />
					}
				</Button>
			</RequireConfirmationCodeItem>

		</form>
	);
};

// J2txTransferForm.propTypes = {
// 	// название формы
// 	form: PropTypes.string.isRequired,

// 	onFail: PropTypes.func.isRequired,
// 	onSuccess: PropTypes.func.isRequired,
// 	onSubmit: PropTypes.func.isRequired,

// 	// нижние ограничения на переводы (если есть) 
// 	restrictions: PropTypes.shape({
// 		BTC: PropTypes.number,
// 		ETH: PropTypes.number,
// 		USDT: PropTypes.number,
// 		LTC: PropTypes.number,
// 		XRP: PropTypes.number,
// 	  }),

// 	// дефолтные значения для redux-form 
// 	initialValues: PropTypes.shape({
// 		TradeCodeFrom: PropTypes.string.isRequired,
// 		ClearingFirmFrom: PropTypes.string,
// 		SummCurrency: PropTypes.string.isRequired,
// 		TradeCodeTo: PropTypes.string,
// 		ClearingFirmTo: PropTypes.string,
// 	  }),

// 	// список валют, которые можно переводить
// 	currencies: PropTypes.arrayOf(PropTypes.string),

// 	// счета, которые будут в выпадашке куда
// 	destinationAccounts: PropTypes.arrayOf(PropTypes.shape({
// 		TradeCode: PropTypes.string.isRequired,
// 		ClearingFirm: PropTypes.string.isRequired,
// 		DisplayFullName: PropTypes.string.isRequired,
// 		IsDemo: PropTypes.bool.isRequired,
// 	})).isRequired,

// 		// счета, которые будут в выпадашке откуда
// 	sourceAccounts: PropTypes.arrayOf(PropTypes.shape({
// 		TradeCode: PropTypes.string.isRequired,
// 		ClearingFirm: PropTypes.string.isRequired,
// 		DisplayFullName: PropTypes.string.isRequired,
// 		IsDemo: PropTypes.bool.isRequired,
// 	})).isRequired,
// };

function withValidation(Component) {
	function ComponentWithValidation(props) {
		const translate = useTranslate();
		const { TradeCodeFrom, TradeCodeTo, SummCurrency } = useSelector(getFormValues(props.form)) || props.initialValues;
		const summToWithdraw = useBalance(TradeCodeFrom)?.find(x => x.CurrencyCode === SummCurrency)?.Balance;
		const minTransferValuesFrom = useMinTransferValues(TradeCodeFrom);
		const minTransferValuesTo = useMinTransferValues(TradeCodeTo);
		
		const amountFormValidate = (formvalues, props) => {
			const values = formvalues;
			const errors = {};
		
			if (!values) {
				return errors;
			}
		
			if (!values.Summ || values.Summ <= 0) {
				errors.Summ = translate('shared_required', 'Required');
			} else if (typeof summToWithdraw === 'number') {
				if (summToWithdraw < values.Summ) {
					errors.Summ = translate('shared_not_enough_money', 'Insufficient funds for withdrawal');
				}
			}

			// попытка оставить на балансе сумму менее минимальной для перевода и не равной 0
			const remainder = Big(summToWithdraw).minus(values.Summ ?? 0).toNumber();
			if (remainder != 0 && minTransferValuesFrom && remainder < minTransferValuesFrom[SummCurrency]) {
				errors.Summ = translate({
					id: 'minimum_remainder_amount',
					defaultValue: `Remainder amount must be greater than ${minTransferValuesFrom[SummCurrency]} ${SummCurrency} or equal to 0`,
					translateParameters: { amount: minTransferValuesFrom[SummCurrency], currency: SummCurrency }
				});
			}

			// попытка перевода суммы менее минимальной для перевода
			if (minTransferValuesTo && values.Summ < minTransferValuesTo[SummCurrency]){
				errors.Summ = `${translate('minimum_transfer_amount', 'Minimum transfer amount is')} ${minTransferValuesTo[SummCurrency]} ${SummCurrency}`;
			}

			if (!values.Confirmation) {
				errors.Confirmation = translate('shared_required', 'Required');
			}

			if (!values.ConfirmationCode) {
				errors.ConfirmationCode = translate('shared_required', 'Required');
			}
		
			return errors;
		};
		return <Component {...props} validate={amountFormValidate} />;
	}
	return ComponentWithValidation;
}

export default compose(
	withValidation,
	reduxForm({
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		onSubmitFail: (errors, dispatch, submitError, props) => {
			// eslint-disable-next-line no-underscore-dangle
			if (errors._error && props.onFail) {
				props.onFail();
			}
		},
	}),
)(J2txTransferForm);
