import { useCallback } from "react"
import { useDispatch } from "react-redux";
import { withdrawWidget } from '@redux';

class Deferred {
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}

export const useAsyncPostRequest = () => {
    const dispatch = useDispatch();

    return useCallback((payload, errorText) => {
        const deferred = new Deferred();

        dispatch(withdrawWidget.actions.post.request({ 
            resolveCallback: deferred.resolve, 
            rejectCallback: deferred.reject, 
            requestPayload: payload,
            errorText,
        }))

        return deferred.promise;
    }, [dispatch]);
}