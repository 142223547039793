import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm,	Field, formValueSelector } from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import {
	FileInput,
	FormDatePicker,
} from '@components';
import { R, FormInput, withTranslate } from '@dev2t/react-ui-kit/src/components';
import { validateSecureString, parseMomentDate } from '@global';
import { userAccounts } from '@redux';
import { RequireTotpOrSmsOrderConfirmationCodeItem, RequireConfirmationCodeReason } from '@shared';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	inputItem: {
		marginTop: '30px',
	},
	divider: {
		marginTop: '30px',
	},
	fileUploader: {
		marginTop: '16px',
	},
	button: {
		...theme.mixins.stickyButton,
	},
});

const CY_NON_TRADING_ORDER_IDENTITY_FORM_ID = 'CYNonTradingOrderIdentityForm';

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const formSelector = formValueSelector(CY_NON_TRADING_ORDER_IDENTITY_FORM_ID);
	return {
		initialValues: {
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
		},
		attachmentsPassportFiles: formSelector(state, 'PassportFiles'),
		attachmentsCertificateFiles: formSelector(state, 'CertificateFiles'),
	};
};

class CYNonTradingOrderIdentityForm extends React.Component {
	static validate(formvalues, { translate }) {
		const values = formvalues;
		const errors = {};

		if (!values) {
			return errors;
		}

		if ((!values.PassportFiles || values.PassportFiles.length === 0)
			&& (!values.CertificateFiles || values.CertificateFiles.length === 0)) {
			errors.PassportFiles = translate('shared_required', 'Required');
			errors.CertificateFiles = translate('shared_required', 'Required');
		}

		if (!values.FullName) {
			errors.FullName = translate('shared_required', 'Required');
		}

		if (!values.Serial) {
			errors.Serial = translate('shared_required', 'Required');
		}

		if (!values.Number) {
			errors.Number = translate('shared_required', 'Required');
		}

		if (!values.IssuedDate) {
			errors.IssuedDate = translate('shared_required', 'Required');
		}

		if (!values.SubdivisionCode) {
			errors.SubdivisionCode = translate('shared_required', 'Required');
		}

		if (!values.IssuedBy) {
			errors.IssuedBy = translate('shared_required', 'Required');
		}

		if (!values.ConfirmationCode) {
			errors.ConfirmationCode = translate('shared_required', 'Required');
		}

		return errors;
	}

	onFilesUpload = (field, files) => this.props.change(field, files);

	render() {
		const {
			attachmentsPassportFiles,
			attachmentsCertificateFiles,
			classes,
			handleSubmit,
			theme,
			translate,
			submitting,
			invalid,

			...props
		} = this.props;

		const noAttachments = ((!attachmentsPassportFiles || attachmentsPassportFiles.length === 0)
							&& (!attachmentsCertificateFiles || attachmentsCertificateFiles.length === 0));

		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<p>
					<R id="common_nontrading_order_identity_passport_files_copy" defaultValue="Copy of pages of ID document" />
				</p>
				<div className={classes.fileUploader}>
					<FileInput
						onFilesUpdate={files => this.onFilesUpload('PassportFiles', files)}
					/>
				</div>
				<p>
					<R id="common_nontrading_order_identity_certificate_files_copy" defaultValue="Copy of certificate of change" />
				</p>
				<div className={classes.fileUploader}>
					<FileInput
						onFilesUpdate={files => this.onFilesUpload('CertificateFiles', files)}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="FullName"
						label={translate('shared_fullname_copy', 'Full name')}
						multiline
						validate={[validateSecureString]}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="Serial"
						label={translate('common_order_identity_passport_serial_copy', 'Series')}
						validate={[validateSecureString]}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="Number"
						label={translate('common_number_Identification_document', 'Number')}
						validate={[validateSecureString]}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormDatePicker}
						name="IssuedDate"
						label={translate('common_passport_getdate_copy', 'Issued')}
						parse={parseMomentDate}
						onFocus={() => undefined}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="SubdivisionCode"
						label={translate('common_passport_subdivision_code_copy', 'Subdivision code')}
						validate={[validateSecureString]}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="IssuedBy"
						label={translate('common_passport_issued_by_copy', 'Issuing authorityComment')}
						multiline
						validate={[validateSecureString]}
					/>
				</div>
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="Comment"
						label={translate('shared_comment', 'Comment')}
						multiline
						validate={[validateSecureString]}
					/>
				</div>
				
				<RequireTotpOrSmsOrderConfirmationCodeItem
					customClasses={classes}
					confirmationCodeFieldName="ConfirmationCode"
					reason={RequireConfirmationCodeReason.NontradingOrderApply}
					submitDisabled={invalid || noAttachments}
					submitting={submitting}
				/>

			</form>
		);
	}
}

CYNonTradingOrderIdentityForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	withTranslate,
	connect(mapStateToProps, null),
	reduxForm({
		form: CY_NON_TRADING_ORDER_IDENTITY_FORM_ID,
		validate: CYNonTradingOrderIdentityForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		onSubmitFail: (errors, dispatch, submitError, props) => {
			// eslint-disable-next-line no-underscore-dangle
			if (errors._error && props.onFail) {
				props.onFail();
			}
		},
	}),
)(CYNonTradingOrderIdentityForm);
